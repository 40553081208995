:root {
  --max-width: 500px;
}
.App {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: var(--app-height);
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: var(--max-width);
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.header > div {
  width: 100%;
}

.header-content {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
}

.header img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.header p {
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0.3px;
}
.header-desktop p {
  font-size: 16px;
  font-weight: 900;
}

.header-desktop img {
  width: 32px;
  height: 32px;
  object-fit: contain;
}

.payment-gap {
  margin-top: 0;
}

@media screen and (max-width: 900px) {
  .header-content {
    padding: 16px 24px;
  }
}
