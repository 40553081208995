.form {
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  max-width: var(--max-width);
  margin: 0 auto;
}

.form-content {
  padding-top: 97px;
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
}

.form-content_centered {
  align-items: center;
}

.form-right {
  width: 35%;
  padding: 24px;
  background: url('../../assets/11.webp') center/cover no-repeat;
  display: flex;
  flex-direction: column;
  border-radius: 0 12px 12px 0;
}

.form-right-logo {
  margin: auto;
}
.form-right-review {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
}
.stars {
  display: flex;
  gap: 2px;
}

.stars svg {
  width: 24px;
}

.form-rating-text {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
}
