@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
html {
  scroll-behavior: smooth;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
}

body {
  color: #5e6472;
  background-color: #f9f9f9;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(206, 206, 206);
  border-radius: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #2f79e8;
}
button,
label {
  -webkit-tap-highlight-color: transparent;
}
